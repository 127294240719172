<template>
    <el-dialog :append-to-body="true" :title="$t('message.product_list') + ' ' + selectedPackage.barcode" :visible.sync="dialogChangeProcess">
      <div v-loading="loadingData">
        <span v-if="selectedPackProds.length == 0" class="font-bold">
          {{ $t('message.there_is_no_products') }}
        </span>
        <template v-else>
          <el-table
            :data="selectedPackProds"
            style="width: 100%">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              :label="$t('message.name')"
            >
              <template slot-scope="scope">
                {{ scope.row.product ? scope.row.product.name : '' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              :label="$t('message.quantity')"
            >
            </el-table-column>
            <el-table-column
              :label="$t('message.deal')"
            >
              <template slot-scope="scope">
                {{ scope.row.deal ? scope.row.deal.id : '' }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('message.weight')"
            >
              <template slot-scope="scope">
                <el-input 
                  :min="0" 
                  class="mm_custom_input"
                  type="number" 
                  v-model="scope.row.weight"
                >
                    <template slot="append">{{ $t('message.kg') }}</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="text-right mt-5">
        <el-button @click="setProdWeightsInPack" type="primary"> {{ $t('message.save') }} </el-button>
      </div>
    </el-dialog>
</template>
  
<script>
  // @ is an alias to /src
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: "updatePackProdsWeight",
    props: {
        selectedPack: {
            default: Object
        },
    },
    data() {
      return {
        selectedPackage: {},
        selectedPackProds: [],
        loadingData: false,
        dialogChangeProcess: false,
      };
    },

    watch: {
        selectedPack: {
            handler: async function(newVal, oldVal) {
              if(newVal && newVal.products){
                this.selectedPackage = newVal;
                this.selectedPackProds = JSON.parse(JSON.stringify(this.selectedPack.products));
                this.dialogChangeProcess = true;
              } else if(newVal && newVal.id){
                this.selectedPackage = newVal;
                this.loadingData = true;
                this.dialogChangeProcess = true;
                this.showPackageDealProducts(newVal.id)
                    .then(res => {
                      this.selectedPackProds = JSON.parse(JSON.stringify(this.products));
                      this.loadingData = false;
                    })
                    .catch(err => {
                      this.loadingData = false;
                    });
              }
            },
            deep: true,
            immediate: true
        },
    },
  
    computed: {
      ...mapGetters({
        mode: "MODE",
        products: "packageForProductCirculations/dealProducts",
      }),
    },

    methods: {
      ...mapActions({
        showPackageDealProducts: "packageForProductCirculations/showPackageDealProducts",
      }),
      setProdWeightsInPack() {
        let data = this.selectedPackProds.map(el => {
          return {
            id: el.id,
            weight: el.weight,
            quantity: el.quantity,
            product: {
              id: el.product.id, 
              name: el.product.name
            },
            deal: {
              id: el.deal.id, 
            },
          }
        })
        this.$emit('setProdWeightsInPack', data);
        this.dialogChangeProcess = false;
      }
    },
  };
</script>
<style>
  .mm_active{
      color: #E6A23C;
  }
  .mm_process{
      color: #409EFF;
  }
  .mm_success{
      color: #67C23A;
  }
  .link{
      text-decoration: none;
  }
</style>
  
  